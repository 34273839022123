<nav class="navbar navbar-expand-lg navbar-dark bg-dark d-flex">
  <a class="navbar-brand navPosition" href="/">X691 Course Website</a>
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarToggler" [ngbCollapse]="isCollapsed">
    <ul class="me-auto mt-2 mt-lg-0">
      <li class="nav-item active" *ngIf="isLogin==='true'">
       <a class="nav-link" href="/discussion-board">Discussion Board</a>
      </li>
      <li class="nav-item active" *ngIf="isLogin==='true'">
        <a class="nav-link" href="/unsubscribe">Unsubscribe</a>
      </li>
      <li class="nav-item authNav">
        <a class="nav-link" href="/register" *ngIf="isLogin !== 'true'"> Client Registration</a>
      </li>
      <li class="nav-item active" *ngIf="isLogin==='true'">
        <a class="nav-link" href="course-links">Important Course Links</a>
      </li>
      <li class="nav-item active" *ngIf="isLogin==='true'">
          <a class="nav-link" href="/announcement">Announcement</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" href="/projects">Projects</a>
      </li>
      <li class="nav-item active" *ngIf="isLogin==='true'">
        <a class="nav-link" href="/{{userType}}-dashboard">Dashboard</a>
      </li>
    </ul>
    <ul class="ms-auto mt-2 mt-lg-0">
      <li class="nav-item authNav loginout" *ngIf="isLogin!=='true'">
        <a class="nav-link" href="/auth">Login</a>
      </li>
      <li class="nav-item authNav loginout" *ngIf="isLogin==='true'">
        <a class="nav-link" href="/" (click)="logout()">Logout</a>
      </li>
    </ul>
  </div>
</nav>
