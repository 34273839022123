<div class="adminContent">
  <!-- Sidebar Start -->
  <div class="wrapper sideBar">
    <nav id="sidebar">
      <ul class="list-unstyled components sidebarNav">
        <li class="active">
          <a href="admin-dashboard">Overview</a>
        </li>
        <li>
          <a href="members">Members</a>
        </li>
        <li>
          <a href="admin-dashboard">Projects and RFPs</a>
        </li>
        <!--
        <li>
          <a href="admin-dashboard">RFPs</a>
        </li>-->
        <li>
          <a href="admin-create-teams">Create Teams</a>
        </li>
        <li>
          <a href="../changepw">Update Password</a>
        </li>
      </ul>
    </nav>
  </div>
  <!-- Sidebar End -->
  <!-- Column 1 Start -->
  <div class="column1">
    <!-- Pending Project Requests Start -->
    <div class="RFPCard">
      <mat-card>
        <mat-card-title>Pending RFPs</mat-card-title>
        <mat-card-content>
          <table class="table">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Company</th>
              <th scope="col">RFP Link</th>
              <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let r of getPendingRFPs(); index as i">
              <th scope="row">{{i + 1}}</th>
              <td>{{ r.organization }}</td>
              <td (click)="generatePDF(r)" class="linkDisplay"><a href="/admin-dashboard">View RFP</a></td>
              <td>
                <button class="actionButton" (click)="approveRFP(r)">Approve</button>
                <button class="actionButton" (click)="rejectRFP(r)">Reject</button>
              </td>
            </tr>
            </tbody>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Pending Project Requests End -->
    <!-- Rejected Project Requests Start -->
    <div class="RFPCard">
      <mat-card>
        <mat-card-title>Rejected RFPs</mat-card-title>
        <mat-card-content>
          <table class="table">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Company</th>
              <th scope="col">RFP Link</th>
              <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let r of getRejectedRFPs(); index as i">
              <th scope="row">{{i + 1}}</th>
              <td>{{ r.organization }}</td>
              <td (click)="generatePDF(r)" class="linkDisplay"><a href="/admin-dashboard">View RFP</a></td>
              <td>
                <button class="actionButton" (click)="approveRFP(r)">Approve</button>
                <button class="actionButton" (click)="deleteRFP(r)">Delete</button>
              </td>
            </tr>
            </tbody>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Pending Project Requests End -->
    <!-- Active Project Start -->
    <div class="analyticsCard">
      <mat-card>
        <mat-card-title>All Active Projects</mat-card-title>
        <mat-card-content>
          <div id="accordion">
            <div *ngFor="let project of getActiveProjects(); index as i">
              <app-accordian>
                <p header>{{ project.title }}</p>
                <div content>
                  <div class="viewLink">
                    <p>
                      <a href="{{ project.azureLink }}" rel="noopener" target="_blank">Click here to see the Azure board for this project.</a>
                      <!-- image from https://commons.wikimedia.org/wiki/File:Edit_icon_(the_Noun_Project_30184).svg accessed June 4, 2021 -->
                      <input type="image" class="icon" src="..\..\assets\images\editIcon.png" alt="Edit link" (click)="toggleEditLinkTextbox(i)">
                    </p>
                  </div>
                  <div class="editLink" style="display: none">
                    <label for="editLinkTextbox">Enter Azure link:</label>
                    <input type="text" class="editLinkTextbox">
                    <!-- image from https://www.pngwing.com/en/free-png-ynlki/download accessed June 4, 2021 -->
                    <input type="image" class="icon" src="..\..\assets\images\checkIcon.png" alt="Confirm" (click)="editAzureLink(i, project)">
                    <!-- image from https://www.pngjoy.com/preview/p8c3o7r0f0w8i1_cancel-icon-cross-transparent-png/ accessed June 4, 2021 -->
                    <input type="image" class="icon" src="..\..\assets\images\cancelIcon.png" alt="Cancel" (click)="toggleEditLinkTextbox(i)">
                  </div>
                  <br>
                  <br>
                  {{ project.descLong }}
                  <hr>
                  <button class="actionButton" (click)="archiveProject(project)">Archive</button>
                </div>
              </app-accordian>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Active Project End -->
    <!-- Archived Projects Start -->
    <div class="analyticsCard">
      <mat-card>
        <mat-card-title>All Archived Projects</mat-card-title>
        <mat-card-content>
          <div id="accordion">
            <div *ngFor="let project of getArchivedProjects(); index as i">
              <app-accordian>
                <p header>{{ project.title }}</p>
                <div content>
                  <div class="viewLink">
                    <p>
                      <a href="{{ project.azureLink }}" rel="noopener" target="_blank">Click here to see the Azure board for this project.</a>
                      <!-- image from https://commons.wikimedia.org/wiki/File:Edit_icon_(the_Noun_Project_30184).svg accessed June 4, 2021 -->
                      <input type="image" class="icon" src="..\..\assets\images\editIcon.png" alt="Edit link" (click)="toggleEditLinkTextbox(i)">
                    </p>
                  </div>
                  <div class="editLink" style="display: none">
                    <label for="editLinkTextbox">Enter Azure link:</label>
                    <input type="text" class="editLinkTextbox">
                    <!-- image from https://www.pngwing.com/en/free-png-ynlki/download accessed June 4, 2021 -->
                    <input type="image" class="icon" src="..\..\assets\images\checkIcon.png" alt="Confirm" (click)="editAzureLink(i, project)">
                    <!-- image from https://www.pngjoy.com/preview/p8c3o7r0f0w8i1_cancel-icon-cross-transparent-png/ accessed June 4, 2021 -->
                    <input type="image" class="icon" src="..\..\assets\images\cancelIcon.png" alt="Cancel" (click)="toggleEditLinkTextbox(i)">
                  </div>
                  <br>
                  <br>
                  {{ project.descLong }}
                  <hr>
                  <button class="actionButton" (click)="unarchiveProject(project)">Unarchive</button>
                </div>
              </app-accordian>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Archived Projects End -->
    <div class="analyticsCard">
      <mat-card>
        <mat-card-title>Due Dates</mat-card-title>
          <mat-card-content>
            <table class="table">
              <thead>
              <tr>
                <th scope="col">Title</th>
                <th scope="col"> Due Date</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor='let dueDates of getDueDates()'>
                <td>{{dueDates.title}}</td>
                <td>{{ dueDates.date | date:'yyyy/MM/dd h:mm:ss a' }}</td>
              </tr>
              </tbody>
            </table>
          </mat-card-content>
      </mat-card>
    </div>
  </div>
  <!-- Column 1 End -->
  <!-- Column 2 Start -->
  <div class="column2">
    <!-- Announcement Creation start -->
    <div class="otherCard">
      <mat-card>
        <mat-card-title> Create New Announcement </mat-card-title>

        <mat-card-content>
          <p> Fill in the boxes then click the button below to create a new announcement. </p>
        </mat-card-content>

        <mat-card-content>
          <form id="announcementCreation" class="form">
            <mat-form-field class="announcement"><mat-label>Announcement Title: </mat-label><input required name="announcementTitle" id="announcementTitle" matInput type="string"></mat-form-field>
            <mat-form-field class="announcement"><mat-label>Announcement Description: </mat-label><textarea required name="announcementDesc" id="announcementDesc" matInput type="string"></textarea></mat-form-field>
            <button type="reset" class="btn btn-secondary" (click)="CreateAnnouncement()"> Create Announcement </button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Announcement Creation end -->
    <!-- Create new due date -->
    <div class="otherCard">
      <mat-card>
        <mat-card-title> Create New Due Date </mat-card-title>

        <mat-card-content>
          <p> Fill in the boxes then click the button below to create a new  Assignment and Due date (Click on calendar icon to get date and time)</p>
        </mat-card-content>

        <mat-card-content>
          <form id="announcementCreation" class="form">
            <mat-form-field class="announcement"><mat-label>Due date Title: </mat-label><input required name="title" id="duedatetitle" matInput type="string"></mat-form-field>
            <mat-form-field class="announcement"><input type="datetime-local" required name="duedate" id="duedate" matInput type="string"></mat-form-field>
            <button type="reset" class="btn btn-secondary" (click)="dueDates()"> Create Due Date </button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- end of create due date -->
    <!-- csv upload start -->
    <div class="otherCard">
      <mat-card>
        <mat-card-title>Student File Upload</mat-card-title>

        <mat-card-content>
          <label class="form-label" for="customFile">Upload Student CSV</label>
          <input type="file" accept=".csv" class="form-control" id="customFile" (change)="onStudentCSVUpload($event.target.files)"/>
        </mat-card-content>

        <mat-card-content *ngIf="!showConfirm; else showConfirmBlock">
          <button type="button" class="btn btn-secondary" (click)="showConfirmButton()">Batch Create</button>
        </mat-card-content>
        <ng-template #showConfirmBlock>
          <p class="mat-error">This will batch create Student Accounts for each line!</p>
          <span><button type="button" class="btn btn-secondary" (click)="batchCreateStudents()">Confirm</button></span>
          <button type="button" class="btn btn-secondary" (click)="showConfirmButton()">Cancel</button>
        </ng-template>

      </mat-card>
    </div>
    <!-- csv upload end -->
    <!-- RFP Submission Form Upload start -->
    <div class="otherCard">
      <mat-card>
        <mat-card-title>RFP Submission Form Upload</mat-card-title>

        <mat-card-content>
          <label class="form-label" for="customFile">Upload RFP Submission Form</label>
          <input type="file" accept=".json" class="form-control" id="customFile" (change)="onRFPSubmissionFormUpload($event)"/>
        </mat-card-content>

        <mat-card-content>
          <button type="button" class="btn btn-secondary" (click)="uploadRFPSubmissionForm()">Upload Form</button>
        </mat-card-content>

      </mat-card>
    </div>
<!----------------------------->
<!--
  @author: Stefan WU
  @date: October 20, 2021
  
-->
    <div class="otherCard">
      <mat-card>
        <mat-card-title>Dynamic Qusetionnaire Upload</mat-card-title>
        <!--upload JSON file-->
        <mat-card-content>
          <label class="form-label" for="customFile">Upload Dynamic Questionnaire</label>
          <input type="file" accept=".json" class="form-control" id="customFile" (change)="onQuestionnaireUpload($event)"/>
      
        </mat-card-content>

        <mat-card-content>
          <button type="button" class="btn btn-secondary" (click)="uploadQuestionnaire()">Upload Dynamic Questionnaire</button>
        </mat-card-content>

      </mat-card>
    </div>
<!-- ----------------------------------->


    <!-- RFP Submission Form Upload end -->
    <!-- Admin view feedback starts -->
    <div class="otherCard">
      <mat-card>
        <mat-card-title> Client Feedback </mat-card-title>
        <mat-card-content>
          <form>
            <mat-form-field class="teamSelect">
              <mat-label> Team: </mat-label>
              <mat-select name="teamFeedback" id="teamFeedback" (selectionChange)="getTeamFeedback($event.value)">
                <mat-option value="all"> All </mat-option>
                <mat-option *ngFor="let team of getTeams()" value="{{ team.key }}"> {{ team.name }} </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
          <br>
          <div *ngFor="let feedback of GetfeedbackBasedOnTeam()">
            <mat-card>
              <mat-card-title class="feedbackTitle"> {{ feedback.title }} </mat-card-title>
              <mat-card-content> 
                <p class="feedbackBody"> {{ feedback.body }} </p>
                <p class="submitDate"> Submitted on {{ feedback.date | date:'yyyy/MM/dd h:mm:ss a' }} by {{ feedback.client }} </p>
              </mat-card-content>
            </mat-card>
            <br>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Admin view feedback ends -->
  </div>
  <!-- Column 2 End -->
</div>
