<div class="projLayoutDiv lead">
  <mat-card class="projIntro">
    <mat-card-title>Welcome to the Past Projects Page</mat-card-title>
    <mat-card-content >
      We are excited to present both completed and in progress projects
      by our students.  These are projects our students have worked hard on throughout various semesters during their degree. All projects
      are student developed and student led while overseen by excellent teaching assistants and the course professor.
      Some projects can be completed in one semester while other projects will require roll over to additional semesters
      depending on requirements.  Regardless of project team changeover, all projects are completed with excellent
      quality and exceptional dedication.  The student team assigned to your project will work closely with you to
      ensure all that you want from your project is delivered.
    </mat-card-content>
    <mat-card-content>
      Every project submitted is kept in the strictest confidence of our clients and non-disclosure agreements can be
      completed by students if you require. If you are interested in submitting a project with us, we would be happy to
      accept your application.   We are excited to see your project ideas. If you think you have a project that will match
      the the course's direction, please feel free to register as a client and complete an
      <a href="/client-dashboard">RFP (Requirement for Project)</a>. We are excited to work with you.
    </mat-card-content>
  </mat-card>

  <app-past></app-past>
</div>
