<!--
Picture retreived from https://www.dal.ca/faculty/computerscience.html

-->
<div class="homeLayoutDiv lead">
  <div
    class="p-5 text-center bg-image"
    style="
      background-color: #c08c0c;
      background: url('./assets/images/backgroundImage.jpg') no-repeat center center fixed;
      height: 667px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      background-size: cover;
      -o-background-size: cover;
      padding: 100px;
    ">
    <div class="mask">
      <div class="d-flex justify-content-center align-items-center">
        <div>
          <h1 class="pt-1 mt-1 jumboTitle">Welcome to CSCI X691</h1>
          <h4 class="pb-1 mb-1 jumboSubTitle">Dalhousie Community Outreach</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="cases">
    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-2" class="align-items-center">
        <ngb-panel title="CSCI X691 Website">
          <ng-template ngbPanelContent>
            <img class="" style="width: 50%;" src="./assets/images/home.png">
            <img class="" style="width: 50%;" src="./assets/images/dashboard.png">
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
  </div>
  <div class="projIntro card m-auto mt-3 mb-3">
    <div class="card-body">
      <h4 class="card-title bigTitle">Course Introduction</h4>
      <p class="card-text">
        Thank you for visiting the website for Dalhousie's Community Outreach class.  This class provides students with
        invaluable experience working with real-world projects and actual clients to ensure the clients' needs are met.  This
        experience is all gained through group work and in-class guidance that provides students with a safe first experience
        with actual projects.
      </p>
      <p class="card-text">
        In this class, students are placed in groups that consist of second, third and fourth year students, distributed evenly
        to ensure a fair and productive mix of skill, learning and leadership development.  These groups are overseen and
        mentored by exceptional and experienced Teaching Assistants that will ensure both that the students meet the learning
        outcomes and that the clients leave happy with a product that will be valuable to them. Student groups will
        communicate directly with the client in order to fully understand client expectations, desired features and receive
        feedback and provide deliverables at specified increments.  The students themselves will manage the deliverables and
        expectations and present the final product at the end of the designated semester.
      </p>
      <p class="card-text">
        The course welcomes any client project that requires a development component. We are constantly accepting new
        project applications and encourage you to view past completed project <a routerLink="/projects">here</a>.  If you think you have
        a project that would match the courses students well, please feel free to complete a
        <a href="/register">RFP (Requirement for Project)</a>.  We are excited to work with you.
      </p>
    </div>
  </div>

<!--  Three following images were retrieved February 5, 2021 from-->
<!--  https://www.gettyimages.ca/photos/projects?collections=ims&family=creative&license=rf&phrase=projects&sort=mostpopular#license-->
   <div class="card m-auto" style="width: 85%; margin: 1.5rem; background-color: rgba(112, 115, 114, 0.3);">
    <!-- <img class="card-img-top" src="./assets/images/clientImage.jpg" alt="client image"> -->
        <div class="card-body">
          <h5 class="card-title bigTitle">Looking to Become a Client?</h5>
          <p class="card-text">
            This course is focused on providing opportunities for students to learn and grow in their skills while
            providing clients with a quality applications.  If you have a project in the works and are looking for a
            great team, we suggest contacting us to see if we can provide you with what you're looking for. We are
            always open to coordinating with new clients and taking on new projects and look
            forward to hearing from you.
          </p>
          <div class="text-center d-grid gap-2 mx-auto col-4">
            <a href="/register" class="btn btn-lg focus">REGISTER NOW</a>
          </div>

        </div>
      </div>
<div class="topRowLinks row g-4">
      <div class="card focuscard">
        <img class="card-img-top" src="./assets/images/studentImage.jpg" alt="student image">
        <div class="card-body">
          <h5 class="card-title bigTitle">Looking for Past Projects?</h5>
          <p class="card-text">
            Do you want to see what the students have produced before deciding to become a client with us?  Check out
            our Past Projects page to see what kind of projects we take on and the results that we produce.  If there is
            something you're looking to have done that you don't see, send us an email and we can discuss the fit for this course.
          </p>
          <a href="/projects" class="btndal btn btn-lg">READ MORE...</a>
        </div>
      </div>
      <div class="card focuscard">
        <img class="card-img-top" src="./assets/images/pastProjects.jpg" alt="past products">
        <div class="card-body">
          <h5 class="card-title bigTitle">Are you a Student?</h5>
          <p class="card-text">
            If you're a student looking for information on this course, such as how the course will run, the syllabus, or any other
            information, checkout your student dashboard. All students registered for the course will have an account already created for them!
          </p>
          <a href="/auth" class="btn btndal btn-lg">LOGIN NOW</a>
        </div>
      </div>
    <div class="card focuscard">
      <img class="card-img-top" src="./assets/images/DalCS.jpg" alt="Dal CS">
      <div class="card-body">
        <h5 class="card-title bigTitle">Dalhousie University - Computer Science Links</h5>
        <p class="card-text">
          If you're a student or client looking for information regarding Dalhousie's Faculty of Computer Science, such as general information,
          faculty and staff information, contact information, or any other information, checkout the links below. </p>
        <a href="https://www.dal.ca/faculty/computerscience.html" target="_blank" class="btn btndal btn-lg">Dalhousie Faculty of Computer Science</a><br />
        <a href="https://www.dal.ca/faculty/computerscience/about.html" target="_blank" class="btn btndal btn-lg">About Dal Computer Science</a><br />
        <a href="https://www.dal.ca/faculty/computerscience/faculty-staff.html" target="_blank" class="btn btndal btn-lg">Faculty and Staff</a><br />
        <a href="https://www.dal.ca/faculty/computerscience/about/contact.html" target="_blank" class="btn btndal btn-lg">Faculty of Computer Science Contact</a><br />
      </div>
    </div>
  </div>
  <!--
  The below section contains images adopted from https://search.creativecommons.org/search?q=explore, these are placeholder images. Replace
  as necessary. The text is adopted from www.dal.ca to implement a proper bottom section for the website which was missing. The text in quote is cited from www.dal.ca
  -->
  <div class="testimonials">
    <h2 style="padding-left: 15px;" class="bigTitle">Past Client Testimonials</h2>
    <app-testimonials></app-testimonials>
    <a href="/testimonialsPage" class='btn btndal'>See more testimonials ></a>
  </div>
  <div class="topRowLinks row g-4">
    <div class="card focuscard">
      <img class="card-img-top" src="./assets/images/image1.jpg" alt="student image">
      <div class="card-body">
        <h5 class="card-title bigTitle">Explore Dalhousie</h5>
        <p class="card-text">
          Dalhousie University is located in the heart of Atlantic Canada, one of the most prestigious research institute and a home for over 90,00 alumnis.
        </p>
        <a href="https://www.dal.ca/academics/requestinfo/domestic-viewbook.html" target="_blank" class="btn btndal btn-lg">Dalhousie Viewbook</a><br />
        <a href="https://www.dal.ca/about-dal/leadership-and-vision.html" target="_blank" class="btn btndal btn-lg">Leadership & Vision</a><br />
        <a href="https://www.dal.ca/about-dal/internationalization.html" target="_blank" class="btn btndal btn-lg">Internationalization</a><br />
        <a href="https://www.dal.ca/about-dal/indigenous-connection.html" target="_blank" class="btn btndal btn-lg">Indigenous Connection</a><br />
        <a href="https://www.dal.ca" class="btndal btn btn-lg">READ MORE...</a>
      </div>
    </div>
    <div class="card focuscard">
      <img class="card-img-top" src="./assets/images/image2.jpg" alt="past products">
      <div class="card-body">
        <h5 class="card-title bigTitle">Campus Alerts</h5>
        <p class="card-text">
          "Learn more about campus closures and how to get DalSAFE campus alerts!"
        </p>
        <a href="https://www.dal.ca/dept/dalsafe/closures.html" class="btn btndal btn-lg">DAL SAFE</a>
      </div>
    </div>
    <div class="card focuscard">
      <img class="card-img-top" src="./assets/images/image1.jpg" alt="Dal CS">
      <div class="card-body">
        <h5 class="card-title bigTitle">Culture of Respect</h5>
        <p class="card-text">
          "Every person at Dalhousie has a right to be respected and safe. We believe inclusiveness is fundamental to education. We stand for equality." </p>
        <a href="https://www.dal.ca/cultureofrespect.html" class="btndal btn btn-lg">READ MORE...</a>
      </div>
    </div>
  </div>
</div>
