<div>
  <div class="outer">
    <div class="welcome"><p>Resetting your password</p>
      <p>Fill in your email and click the confirmation below. If a matching address is found in our system we will send reset instructions to it.</p></div>
      <form class="form">
        <mat-form-field appearance="outline" class="form-control">
          <mat-label><i>E-Mail</i></mat-label>
          <input matInput type="text" id="email" [(ngModel)]="email" name="email" required>
          <mat-hint>Enter your account's e-mail address</mat-hint>
        </mat-form-field>
<!-- 
        <mat-form-field appearance="outline" class="form-control">
          <mat-label><i>Password</i></mat-label>
          <input matInput type="password" id="password" [(ngModel)]="password" name="password" required>
          <mat-hint>Enter your current password</mat-hint>
        </mat-form-field> -->

        <div class="form-footer"><br>
          <p><a *ngIf="!resetPassword && isValidMailFormat(email)" class="text-danger" (click)="sendResetEmail()">Click to send reset instructions to {{email}}</a></p>
          <p *ngIf="resetPassword" class="text-success">Check your email for instructions on how to reset your password!</p>
          
        </div><br><br>
      </form>
  </div>
</div>
