<div
    class="p-5 text-center bg-image"
    style="
      background-color: #c08c0c;
    ">
    <div class="mask">
      <div class="d-flex justify-content-center align-items-center">
        <div>
          <h1 class="pt-1 mt-1 jumboTitle">CSCI X691</h1>
          <h4 class="pb-1 mb-1 jumboSubTitle">Dalhousie Community Outreach</h4>


        </div>
      </div>
    </div>
  </div>
  <form>
    <div>
            <mat-form-field class="email"><mat-label>Email </mat-label><textarea required name="email" id="email" matInput type="string"></textarea></mat-form-field>
            <p>Please double check your current login email address to unsubscribe!</p>
            <button type="reset" class="btn btn-secondary" (click)="updateEmail()"> Unsubscribe </button>
        <br>
    </div>
  </form>
