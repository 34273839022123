<div class="clientContent">
  <!-- Sidebar Start -->
  <div class="wrapper sideBar">
    <nav id="sidebar">
      <ul class="list-unstyled components sidebarNav">
        <li class="active">
          <a href="client-dashboard">Overview</a>
        </li>
        <li>
          <a href="client-dashboard">Submitted RFP's</a>
        </li>
        <li>
          <a href="client-dashboard">Past Projects</a>
        </li>
        <li>
          <a href="client-dashboard">Other</a>
        </li>
        <li>
          <a href="../changepw">Update Password</a>
      </ul>
    </nav>
  </div>
  <!-- Sidebar End -->
  <!-- Column 1 Start -->
  <div class="column1">
    <!-- Pending Project Requests Start -->
    <div class="RequestsCard " >
      <mat-card>
        <mat-card-title>My Submitted Requests</mat-card-title>
        <mat-card-content>
          <table class="table">
            <thead>
            <tr>
              <th scope="col">Project Title</th>
              <th scope="col">Organization</th>
              <th scope="col">RFP</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor='let RFPs of getMyRFPs()'>
              <td>{{RFPs.projectTitle}}</td>
              <td>{{RFPs.organization}}</td>
              <td (click)="generatePDF(RFPs)" class="linkDisplay"><a href="/client-dashboard">View RFP</a></td>
              <td>{{RFPs.status}}</td>
              <td>
                <div *ngIf="RFPs.status != 'Approved'">
                  <button class="actionButton" routerLink="/editRFP/{{RFPs.key}}">Edit</button>
                </div>
              </td>
            </tr>

            </tbody>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Pending Project Requests End -->
    <!-- Active Project Start -->
    <div class="projectsCard">
      <mat-card>
        <mat-card-title>My Projects</mat-card-title>
        <mat-card-content>
          <div id="accordion">
            <div *ngFor="let project of getMyProjects()">
              <app-accordian>
                <p header>{{ project.title }}</p>
                <div content>
                  <a href="{{ project.azureLink }}" rel="noopener" target="_blank">Click here to see the Azure board for this project.</a>
                    <br>
                    <br>
                    {{ project.descLong }}
                </div>
              </app-accordian>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Feedback From client start -->
    <div class="projectsCard">
      <mat-card>
        <mat-card-title>Feedback </mat-card-title>
        <mat-card-content>
          <p> Fill in the boxes then click the button below to post a feedback for a team </p>
        </mat-card-content>
        <mat-card-content>
          <div id="accordion">
              <form id="FeedbackCreation" class="form">
                <mat-form-field class="feedback"><mat-label>Team:</mat-label>
                  <mat-select name="feedbackTeam" id="feedbackTeam" (selectionChange)="getFeedbackTeam($event.value)">              
                      <mat-option *ngFor="let team of getMyTeams()" value="{{ team.key }}">{{ team.name }}</mat-option>    
                  </mat-select>
              </mat-form-field>
                <mat-form-field class="feedback"><mat-label>Title: </mat-label><input required name="feedbackTitle" id="feedbackTitle" matInput type="string"></mat-form-field>
                <mat-form-field class="feedback"><mat-label>Feedback: </mat-label><textarea required name="feedbackBody" id="feedbackBody" matInput type="string"></textarea></mat-form-field>
                <button type="reset" class="btn btn-secondary" (click)="CreateFeedback()"> Create Feedback</button>
              </form>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Feedback From client End -->
    <!-- Active Project End -->
    <!-- RFP Section Start -->
    <div class="RFPCard">
      <mat-card>
        <mat-card-title>Submit Request For Project</mat-card-title>
        <mat-card-content>
          <form id="submitRFP">
			      <button class="submitRFP" routerLink='/submitRFP' mat-button>Submit RFP</button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- RFP Section End -->
  </div>
  <!-- Column 1 End -->
  <!-- Column 2 Start -->
  <div class="column2">
    <!-- Iteration Start -->
    <div class="evaluationCard">
      <mat-card class="members1">
        <mat-card-title>Iteration Evaluations</mat-card-title>
        <mat-card-content>
          No Iterations Evaluations have been completed
        </mat-card-content>
      </mat-card>
      <!-- Iteration Evaluations ends -->
      <!-- Upcoming Iterations -->
      <mat-card class="members2">
        <mat-card-title> Create New Testimonial </mat-card-title>

        <mat-card-content>
          <p> Fill in the boxes then click the button below to create a new testimonial. </p>
        </mat-card-content>

        <mat-card-content>
          <form id="testimonialCreation" class="form">
            <mat-form-field class="testimonial"><mat-label>Testimonial Title: </mat-label><input required name="testimonialTitle" id="testimonialTitle" matInput type="string"></mat-form-field>
            <mat-form-field class="testimonial"><mat-label>Testimonial Description: </mat-label><textarea required name="testimonialDesc" id="testimonialDesc" matInput type="string"></textarea></mat-form-field>
            <mat-form-field class="testimonial"><mat-label>Testimonial Rating: </mat-label><textarea required name="testimonialRating" id="testimonialRating" matInput type="string"></textarea></mat-form-field>
            <button type="reset" class="btn btn-secondary" (click)="CreateTestimonial()"> Create Testimonial </button>
          </form>
        </mat-card-content>
      </mat-card>
      <mat-card class="members3">
        <mat-card-title>Azure Links</mat-card-title>
        <mat-card-content>
          <table class="table">
            <thead>
            <tr>
              <th scope="col">Project</th>
              <th scope="col">Azure Link</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor='let Projects of getMyProjects()'>
              <td>{{Projects.title}}</td>
              <td><a href="{{Projects.azureLink}}" rel="noopener" target="_blank">Link</a></td>
            </tr>

            </tbody>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Members End -->
    <!-- Other Start End -->
    <div class="otherCard">
      <mat-card>
        <mat-card-title>Course Overview</mat-card-title>
        <mat-card-content>
          Thank you for visiting the website for Dalhousie's Community Outreach class.  This class provides students with
          invaluable experience working with real-world projects and actual clients to ensure the clients' needs are met.  This
          experience is all gained through group work and in-class guidance that provides students with a safe first experience
          with actual projects.
        </mat-card-content>
        <mat-card-content>
          In this class, students are placed in groups that consist of second, third and fourth year students, distributed evenly
          to ensure a fair and productive mix of skill, learning and leadership development.  These groups are overseen and
          mentored by exceptional and experienced Teaching Assistants that will ensure both that the students meet the learning
          outcomes and that the clients leave happy with a product that will be valuable to them. Student groups will
          communicate directly with the client in order to fully understand client expectations, desired features and receive
          feedback and provide deliverables at specified increments.  The students themselves will manage the deliverables and
          expectations and present the final product at the end of the designated semester.
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <!-- Column 2 End -->
</div>
