<div class="mb-3">
  <label for="exampleFormControlTextarea1" class="form-label">Discussion Title</label>
  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
</div>
<div class="mb-3">
  <label for="exampleFormControlTextarea1" class="form-label">Discussion post</label>
  <textarea class="form-control" id="exampleFormControlTextarea2" rows="3"></textarea>
</div>
<div class="container">
  <div class="centre">
    <button type="reset"   (click)="CreateDiscussion()"> Post Discussion </button>
    <!-- <button type="button" class="btn btn-warning">Post Discussion</button> -->
  </div>
</div>


