<div class='holder lead'>
  <mat-card class="headerstyle pb-5 pt-5">
    <mat-card-title  class="jumboSubTitle">All Testimonials</mat-card-title>
    <mat-card-content>
      Interested in submitting an RPF for this class?
      Here's what some previous clients have to say about the results of this class.
      <br />
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt arcu vel diam sodales semper sed at lacus. Maecenas auctor nisl ultricies elit venenatis imperdiet. Curabitur est erat, accumsan nec neque a, posuere suscipit diam. Maecenas accumsan a libero eu tincidunt.
      <br />
      In hac habitasse platea dictumst. Proin eget accumsan eros. Integer eget augue ut sem tincidunt accumsan vitae sed leo.
    </mat-card-content>
  </mat-card>
  <div class="box">
    <div class="card card-block" *ngFor='let testimonial of testimonials; index as i' >
      <h2 class="card-title">{{testimonial['client_name']}}</h2>
      <div class="stars">
        <div *ngFor="let star of getStars(testimonial)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill singleStar" viewBox="0 0 16 16">
            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
          </svg>
        </div>
      </div>

      <h4 class="card-subtitle">{{testimonial['client_designation'] + ", " + testimonial['company_name']}}</h4>
      <p class="card-text">{{testimonial['testimonial_text']}}</p>
    </div>
  </div>
</div>
