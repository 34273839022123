<div class="clientContent">
    <!-- Sidebar Start -->
    <div class="wrapper sideBar">
      <nav id="sidebar">
        <ul class="list-unstyled components sidebarNav">
          <li class="active">
            <a href="student-dashboard">Overview</a>
          </li>
          <li>
            <a href="student-dashboard">Submitted RFP's</a>
          </li>
          <li>
            <a href="student-dashboard">Azure Links</a>
          </li>
          <li>
            <a href="student-dashboard">Past Projects</a>
          </li>
          <li>
            <a href="student-questionnaire">Starter Questionnaire</a>
          </li>
          <li>
            <a>Dynamic Questionnaire</a>
          </li>
          <li>
            <a href="student-dashboard">Other</a>
          </li>
          <li>
            <a href="../changepw">Update Password</a>
        </ul>
      </nav>
    </div>
  <!-- Sidebar End -->

  <!--  ---------------------------->
    <div class="quest">
        <form >
            <h1>Starter Questionnaire</h1>
          <label class="title">1. Family Name *</label>
          <br>
          <input class="bar" id="familyName" type="text" required>
          <br>
          <br>
          <label class="title">2. First Name *</label>
          <br>
          <input class="bar" id="firstName" type="text" required>
          <br>
          <br>
          <label class="title">3. Your banner number starting with a B *</label>
          <br>
          <input class="bar" id="b00" type="text">
          <br>
          <br>
          <label class="title">4. Which course are you registered in? *</label>
          <br>
          <select id="courses" >
            <option value="CSCI 2691">CSCI 2691</option>
            <option value="CSCI 3691">CSCI 3691</option>
            <option value="CSCI 4691">CSCI 4691</option>
          </select>
          <br>
          <br>
          <label class="title">5. Project Choice #1 *</label>
          <br>
          <select id="projectChoice1" >
            <option *ngFor='let Projects of getProjects()' value={{Projects.title}}>{{Projects.title}}</option>
          </select>
          <br>
          <br>
          <label class="title">6. Project Choice #2 *</label>
          <br>
          <select id="projectChoice2" >
            <option *ngFor='let Projects of getProjects()' value={{Projects.title}}>{{Projects.title}}</option>
          </select>
          <br>
          <br>
          <label class="title">7. Project Choice #3 *</label>
          <br>
          <select id="projectChoice3" >
            <option *ngFor='let Projects of getProjects()' value={{Projects.title}}>{{Projects.title}}</option>
          </select>
          <br>
          <br>
          <label class="title">8. Would you like to be a leader *</label>
          <br>
          <input (change)="leaderCheck($event)" class="rad" type="radio" name="leader" value="Yes" checked>
          <label>&nbsp; Yes</label><br>
          <input (change)="leaderCheck($event)" class="rad" type="radio" name="leader" value="No" checked>
          <label>&nbsp; No</label><br>
          <br>
          <label class="title">9. Have you taken any of CSCI 2691, 3691, 4691 in Summer 2020, or Fall 2020? *</label>
          <br>
          <input (change)="pastCoursesCheck($event)" class="rad" type="radio" name="pastCourses" value="Yes">
          <label>&nbsp; Yes</label><br>
          <input (change)="pastCoursesCheck($event)" class="rad" type="radio" name="pastCourses" value="No">
          <label>&nbsp;  No</label><br>
          <br>

          <label class="title">10. Would you like to be a student representative? *</label>
          <br>
          <input (change)="studentRepCheck($event)" class="rad" type="radio" name="studentRep" value="Yes">
          <label>&nbsp; Yes</label><br>
          <input (change)="studentRepCheck($event)" class="rad" type="radio" name="studentRep" value="No">
          <label>&nbsp;  No</label><br>
          <br>

          <label class="title">11. Are you comfortable accepting the IP and NDA policies of the client? *</label>
          <br>
          <input (change)="ipNDACheck($event)" class="rad" type="radio" name="ipNDA" value="Yes">
          <label>&nbsp;  Yes</label><br>
          <input (change)="ipNDACheck($event)" class="rad" type="radio" name="ipNDA" value="No">
          <label>&nbsp;  No</label><br>
          <br>

          <label class="title">12. Have you taken any of the remote versions of CSCI X691 offered in Winter 2020,
            <br>Summer 2020, Fall 2021, Winter 2021 *</label>
          <br>
          <input (change)="pastRemoteCheck($event)" class="rad" type="radio" name="pastRemote" value="Yes">
          <label>&nbsp;  Yes</label><br>
          <input (change)="pastRemoteCheck($event)" class="rad" type="radio" name="pastRemote" value="No">
          <label>&nbsp;  No</label><br>
          <br>

          <label class="title">13. If you answered Yes to the previous question, how was your experience and
            <br>what is your comment on it? Tell us if there is anything we can improve this term.</label>
          <br>
          <input class="bar" id="improve" type="text">
          <br>
          <br>
          <label class="title">14. Do you need any accommodation? see <br>
            https://www.dal.ca/campus_life/academic-support/<br>accessibility/accommodations-.html</label>
          <br>
          <input (change)="accCheck($event)" class="rad" type="radio" name="acc" value="Yes">
          <label >&nbsp; Yes</label><br>
          <input (change)="accCheck($event)" class="rad" type="radio" name="acc" value="No">
          <label>&nbsp; No</label><br>
          <br>
          <label class="title">15. Operating systems in the laptops you use *</label>
          <br>
          <input class="bar" id="operatingSystem" type="text">
          <br>
          <br>
          <label>16. Explain what background/experience you have that you would<br>
             bring to the project. For example, relevant courses you have taken,
             <br>programming languages you know, co-op and/or work experience
             <br>you have, other projects you have worked on, etc. *</label>
          <br>
          <input class="bar" id="backgroundEX" type="text">
          <br>
          <br>

          <label class="title">17. Do you have any experience in Agile Software development? *</label>
          <br>
          <input (change)="pastAgileSoftCheck($event)" class="rad" type="radio" name="pastAgileSoft" value="I do not know what is Agile">
          <label>&nbsp; I do not know what is Agile</label><br>
          <input (change)="pastAgileSoftCheck($event)" class="rad" type="radio" name="pastAgileSoft" value="No experience but know the theory">
          <label>&nbsp;  No experience but know the theory</label><br>
          <input (change)="pastAgileSoftCheck($event)" class="rad" type="radio" name="pastAgileSoft"  value="Little experience">
          <label >&nbsp;  Little experience</label><br>
          <input (change)="pastAgileSoftCheck($event)" class="rad" type="radio" name="pastAgileSoft"  value="Considerable experience">
          <label >&nbsp;  Considerable experience</label><br>
          <input (change)="pastAgileSoftCheck($event)" class="rad" type="radio" name="pastAgileSoft" value="I call myself Agile Practitioner">
          <label >&nbsp;  I call myself Agile Practitioner</label><br>
          <br>

          <label class="title">18. How much are you familiar with GitLab? *</label>
          <br>
          <input (change)="pastGitEXCheck($event)" class="rad" type="radio" name="pastGitEX" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastGitEXCheck($event)" class="rad" type="radio" name="pastGitEX" value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastGitEXCheck($event)" class="rad" type="radio" name="pastGitEX" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastGitEXCheck($event)" class="rad" type="radio" name="pastGitEX" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">19. How much are you familiar with Agile/Scrum? *</label>
          <br>
          <input (change)="pastAgileScrumCheck($event)" class="rad" type="radio" name="pastAgileScrum" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastAgileScrumCheck($event)" class="rad" type="radio" name="pastAgileScrum" value="Low">
          <label> &nbsp; Low</label><br>
          <input (change)="pastAgileScrumCheck($event)" class="rad" type="radio" name="pastAgileScrum" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastAgileScrumCheck($event)" class="rad" type="radio" name="pastAgileScrum" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">20. How much do you familiar with AngularJS/Angular? *</label>
          <br>
          <input (change)="pastAngularCheck($event)" class="rad" type="radio" name="pastAngular" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastAngularCheck($event)" class="rad" type="radio" name="pastAngular" value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastAngularCheck($event)" class="rad" type="radio" name="pastAngular" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastAngularCheck($event)" class="rad" type="radio" name="pastAngular" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">21. How much are you familiar with ReactJS? *</label>
          <br>
          <input (change)="pastReactCheck($event)" class="rad" type="radio" name="pastReact" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastReactCheck($event)" class="rad" type="radio" name="pastReact" value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastReactCheck($event)" class="rad" type="radio" name="pastReact" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastReactCheck($event)" class="rad" type="radio" name="pastReact" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">22. How much are you familiar with NextJS? *</label>
          <br>
          <input (change)="pastNextCheck($event)" class="rad" type="radio" name="pastNext" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastNextCheck($event)" class="rad" type="radio" name="pastNext" value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastNextCheck($event)" class="rad" type="radio" name="pastNext" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastNextCheck($event)" class="rad" type="radio" name="pastNext" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">23. How much are you familiar with Flutter? *</label>
          <br>
          <input (change)="pastFlutterCheck($event)" class="rad" type="radio" name="pastFlutter" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastFlutterCheck($event)" class="rad" type="radio" name="pastFlutter" value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastFlutterCheck($event)" class="rad" type="radio" name="pastFlutter" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastFlutterCheck($event)" class="rad" type="radio" name="pastFlutter" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">24. How much are you familiar with Firebase? *</label>
          <br>
          <input (change)="pastFirebaseCheck($event)" class="rad" type="radio" name="pastFirebase" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastFirebaseCheck($event)" class="rad" type="radio" name="pastFirebase" value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastFirebaseCheck($event)" class="rad" type="radio" name="pastFirebase" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastFirebaseCheck($event)" class="rad" type="radio" name="pastFirebase" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">25. How much are you familiar with Azure Board? *</label>
          <br>
          <input (change)="pastAzureCheck($event)" class="rad" type="radio" name="pastAzure" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastAzureCheck($event)" class="rad" type="radio" name="pastAzure"  value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastAzureCheck($event)" class="rad" type="radio" name="pastAzure"  value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastAzureCheck($event)" class="rad" type="radio" name="pastAzure"  value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">26. How much are you familiar with AEM? *</label>
          <br>
          <input (change)="pastAEMCheck($event)" class="rad" type="radio" name="pastAEM" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastAEMCheck($event)" class="rad" type="radio" name="pastAEM" value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastAEMCheck($event)" class="rad" type="radio" name="pastAEM" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastAEMCheck($event)" class="rad" type="radio" name="pastAEM" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">27. How much are you familiar with HTML,CSS? *</label>
          <br>
          <input (change)="pastHTMLCSSCheck($event)" class="rad" type="radio" name="pastHTMLCSS" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastHTMLCSSCheck($event)" class="rad" type="radio" name="pastHTMLCSS" value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastHTMLCSSCheck($event)" class="rad" type="radio" name="pastHTMLCSS" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastHTMLCSSCheck($event)" class="rad" type="radio" name="pastHTMLCSS" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">28. How much are you familiar with PHP? *</label>
          <br>
          <input (change)="pastPHPCheck($event)" class="rad" type="radio" name="pastPHP" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastPHPCheck($event)" class="rad" type="radio" name="pastPHP" value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastPHPCheck($event)" class="rad" type="radio" name="pastPHP" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastPHPCheck($event)" class="rad" type="radio" name="pastPHP" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">29. How much are you familiar with wireframing? *</label>
          <br>
          <input (change)="pastWireCheck($event)" class="rad" type="radio" name="pastWire" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastWireCheck($event)" class="rad" type="radio" name="pastWire" value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastWireCheck($event)" class="rad" type="radio" name="pastWire" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastWireCheck($event)" class="rad" type="radio" name="pastWire" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">30. How much are you familiar with digital animation? *</label>
          <br>
          <input (change)="pastAnimationCheck($event)" class="rad" type="radio" name="pastAnimation" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastAnimationCheck($event)" class="rad" type="radio" name="pastAnimation" value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastAnimationCheck($event)" class="rad" type="radio" name="pastAnimation" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastAnimationCheck($event)" class="rad" type="radio" name="pastAnimation" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <label class="title">31. How much are you familiar with augmented reality? *</label>
          <br>
          <input (change)="pastRealityCheck($event)" class="rad" type="radio" name="pastReality" value="Not at all">
          <label>&nbsp;  Not at all </label><br>
          <input (change)="pastRealityCheck($event)" class="rad" type="radio" name="pastReality" value="Low">
          <label>&nbsp;  Low</label><br>
          <input (change)="pastRealityCheck($event)" class="rad" type="radio" name="pastReality" value="Average level">
          <label>&nbsp;  Average level</label><br>
          <input (change)="pastRealityCheck($event)" class="rad" type="radio" name="pastReality" value="Use it frequently">
          <label>&nbsp;  Use it frequently</label><br>
          <br>

          <br>
          <label class="title">32. From which time zone (country, city and time difference to Halifax)
            <br>you are connecting to the course? *</label>
          <br>
          <input class="bar" id="timeZone" type="text">
          <br>
          <button (click)="addQuest()" type="submit" value="Submit"> Submit </button>
        </form>
      </div>
