

<div class="clientContent">
  <!-- Sidebar Start -->
  <div class="wrapper sideBar">
    <nav id="sidebar">
      <ul class="list-unstyled components sidebarNav">
        <li class="active">
          <a href="student-dashboard">Overview</a>
        </li>
        <li>
          <a href="student-dashboard">Submitted RFP's</a>
        </li>
        <li>
          <a href="student-dashboard">Azure Links</a>
        </li>
        <li>
          <a href="student-dashboard">Past Projects</a>
        </li>
        <li>
          <a href="student-questionnaire">Starter Questionnaire</a>
        </li>
        <li>
          <a>Dynamic Questionnaire</a>
        </li>
        <li>
          <a href="student-dashboard">Other</a>
        </li>
        <li>
          <a href="../changepw">Update Password</a>
      </ul>
    </nav>
  </div>

  <!-- Sidebar End -->
  <!-- Column 1 Start -->
  <div class="column1">
    <!-- my current project(s) Start -->
    <div class="projectsCard">
      <mat-card>
        <mat-card-title>My Current Project</mat-card-title>
        <mat-card-content>
          <div id="accordion">
            <div *ngFor="let project of getMyActiveProjects()">
              <app-accordian>
                <p header>{{ project.title }}</p>
                <div content>
                  <a href="{{ project.azureLink }}" rel="noopener" target="_blank">Click here to see the Azure board for this project.</a>
                      <br> <br>
                      {{ project.descLong }}
                </div>
              </app-accordian>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- my current project(s) end -->
    <!-- All Active Projects Start -->
    <div class="projectsCard">
      <mat-card>
        <mat-card-title>All Active Projects</mat-card-title>
        <mat-card-content>
          <div id="accordion">
            <div *ngFor="let project of getActiveProjects()">
              <app-accordian>
                <p header>{{ project.title }}</p>
                <div content>
                    {{ project.descLong }}
                </div>
              </app-accordian>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- All Active Projects end -->
    <!-- My Past Projects Start -->
    <div class="projectsCard">
      <mat-card>
        <mat-card-title>My Past Projects</mat-card-title>
        <mat-card-content>
          <div id="accordion">
            <div *ngFor="let project of getMyPastProjects()">
              <app-accordian>
                <p header>{{ project.title }}</p>
                <div content>{{ project.descLong }}</div>
              </app-accordian>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- My Past Projects End -->
    <!-- Due date start -->
    <div class="dueDates">
      <mat-card>
        <mat-card-title>Due Dates</mat-card-title>
        <mat-card-content>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Title</th>
                <th scope="col"> DueDate</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor='let dueDates of getDueDates()'>
                <td>{{dueDates.title}}</td>
                <td>{{ dueDates.date | date:'yyyy/MM/dd h:mm:ss a' }}</td>
              </tr>
            </tbody>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <!-- Column 1 End -->
  <!-- Column 2 Start -->
  <div class="column2">
    <!-- Iteration Start -->
    <div class="evaluationCard">
      <!-- Upcoming Iterations -->
      <mat-card class="members2">
        <mat-card-title>Upcoming Iterations</mat-card-title>
        <mat-card-content>
          You have no upcoming Iterations
        </mat-card-content>
      </mat-card>
      <mat-card class="members1">
        <mat-card-title>Iteration Evaluations</mat-card-title>
        <mat-card-content>
          No Iterations Evaluations have been completed
        </mat-card-content>
      </mat-card>
      <mat-card class="members3">
        <mat-card-title>Azure Links</mat-card-title>
        <mat-card-content>
          <table class="table">
            <thead>
            <tr>
              <th scope="col">Project</th>
              <th scope="col">Azure Link</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor='let Projects of getMyActiveProjects()'>
              <td>{{Projects.title}}</td>
              <td><a href="{{Projects.azureLink}}" rel="noopener" target="_blank">Link</a></td>
            </tr>
            </tbody>
          </table>
        </mat-card-content>
      </mat-card>
      <!-- Iteration Evaluations ends -->
    </div>
    <!-- Members End -->
    <!-- Client feedback starts -->
    <div class="announcements">
      <mat-card>
        <mat-card-title> Client Feedback </mat-card-title>
        <mat-card-content>
          <div *ngFor='let feedback of getTeamFeedback()'>
            <mat-card>
              <mat-card-title class="feedbackTitle"> {{ feedback.title }} </mat-card-title>
              <mat-card-content>
                <p class="feedbackBody"> {{ feedback.body }} </p>
                <p class="submitDate"> Submitted on {{ feedback.date | date:'yyyy/MM/dd h:mm:ss a' }} by {{ feedback.client }}</p>
              </mat-card-content>
            </mat-card>
            <br>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Client feedback ends -->
  <!-- Column 2 End -->
</div>
